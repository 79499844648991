import { Autocomplete, Box, Button, TextField, Typography, Divider, List, ListItem, Drawer, ListItemButton, Modal } from "@mui/material";
import { modalStyle, modalStyleOverflow } from "../../../theme/ModalStyle";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../../components/ui/ContentBox";
import * as Yup from "yup";
import UserType from "../../../components/ui/UserType";
import TableShowUser from "../../../components/ui/TableShowUser";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categories_columns } from "../../../components/data/categoriesColumns";
import moment from "moment";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";
import TableShowCategory from "../../../components/ui/TableShowCategory";
import { Formik, ErrorMessage, Field, Form } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { colors } from "../../../theme/Colors";
import { CircularProgress } from "@mui/material";

function Categories() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [didCreate, setDidCreate] = useState(false);
  const [didUpdate, setDidUpdate] = useState(false);
  const [didDelete, setDidDelete] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState();

  const handleCloseDeleteCategory = () => {
    setOpenDeleteCategory(false);
  };

  const handleOpenDeleteCategory = () => {
    setOpenDeleteCategory(true);
  };

  const handleDeleteCategory = () => {
    Api.delete(Urls.STORE_CATEGORY_DELETE + `/${selectedRowToDelete}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        toast.success(response?.data?.message);
        setDidDelete(!didDelete);
        setOpenDeleteCategory(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    setLoading(true);

    Api.get(Urls.STORE_GET_CATEGORIES + perPage, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { name: search }),
      },
    })
      .then((response) => {
        console.log("categories list:", response.data.data);
        setCategories(response?.data?.data);
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  }, [didCreate, didUpdate, didDelete, search]);

  const perPage = "?perPage=10";

  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT categories", res?.data);
        setData(res?.data);
        setCategories(res.data?.data);
        console.log(res?.data?.meta?.links, "link page");
      })
      .catch((err) => {
        console.log(err);
        setCategories([]);
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  //FileUploadTextField
  const FileUploadTextField = ({ field, form, ...props }) => {
    const handleFileChange = (event) => {
      form.setFieldValue(field.name, event.currentTarget.files[0]);
    };

    return (
      <TextField
        {...props}
        id={field.name}
        type="text"
        value={field.value ? field.value.name : ""}
        InputProps={{
          startAdornment: (
            <label htmlFor={field.name}>
              <input type="file" id={field.name} name={field.name} style={{ display: "none" }} onChange={handleFileChange} accept="image/*" />
              <Button sx={{ borderRadius: "0px", marginLeft: "-10px", width: "150px", borderRight: "1px solid #dedfe4" }} component="span" type="file">
                <Typography color="#4B465C">Choose image</Typography>
              </Button>
            </label>
          ),
          endAdornment: <CircularProgress sx={{ padding: "5px" }} variant="determinate" value={progressUploadFiles} />,
        }}
      ></TextField>
    );
  };

  //drawers
  const [stateUpdate, setStateUpdate] = React.useState({
    right: false,
  });

  const [stateCreate, setStateCreate] = React.useState({
    right: false,
  });

  const toggleDrawerUpdate = (anchor, open, id) => (event) => {
    if (id) {
      Api.get(Urls.STORE_GET_CATEGORIES + `/${id}`, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((response) => {
          console.log("singleCategory:", response.data.data);
          setCategory(response?.data?.data);
        })
        .catch((error) => {
          console.log("error");
          toast.error(error?.response?.data?.message);
        });
    }

    console.log("toggled Update");
    console.log("state toggle ", stateUpdate);
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setStateUpdate({ ...stateUpdate, [anchor]: open });
  };

  const toggleDrawerCreate = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setStateCreate({ ...stateCreate, [anchor]: open });
  };

  //validation schema
  const validateFileType = (file) => {
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        return true;
      }
    }
    return false;
  };

  //validateFileType update
  const validateFileTypeUpdate = (file) => {
    if (file === null) return true;
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (allowedTypes.includes(file?.type)) {
      return true;
    }
    return false;
  };

  const validateFileSize = (file) => {
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        return true;
      }
    }
    return false;
  };

  const validateFileSizeUpdate = (file) => {
    if (file === null) return true;
    const maxSize = 5 * 1024 * 1024;
    if (file?.size <= maxSize) {
      return true;
    }

    return false;
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    icon: Yup.mixed()
      .test("fileType", "Invalid file type", (file) => validateFileType(file))
      .test("fileSize", "File size exceeds 5MB", (file) => validateFileSize(file)),
    file: Yup.mixed()
      .test("fileType", "Invalid file type", (file) => validateFileType(file))
      .test("fileSize", "File size exceeds 5MB", (file) => validateFileSize(file)),
  });

  const validationSchemaUpdate = Yup.object({
    name: Yup.string(),
    icon: Yup.mixed()
      .nullable()
      .test("fileType", "Invalid file type", (file) => validateFileTypeUpdate(file))
      .test("fileSize", "File size exceeds 5MB", (file) => validateFileSizeUpdate(file)),
    file: Yup.mixed()
      .nullable()
      .test("fileType", "Invalid file type", (file) => validateFileTypeUpdate(file))
      .test("fileSize", "File size exceeds 5MB", (file) => validateFileSizeUpdate(file)),
  });

  function isObjectEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  //upload picture
  const [progressUploadFiles, setProgressUploadFiles] = useState(0);
  const [fileCategoryCover, setFileCategoryCover] = useState(null);
  const [fileCategoryIcon, setFileCategoryIcon] = useState(null);
  useEffect(() => {
    document.title = "BTA admin - Store";
  }, []);
  var configsPic = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      console.log(progressEvent);
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUploadFiles(percentCompleted);
    },
  };

  //submit new category
  const onSubmitCreateCategory = (values) => {
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("file", values?.icon);
    formData.append("icon", values?.file);

    Api.post(Urls?.STORE_CATEGORY_CREATE, formData, configsPic)
      .then((response) => {
        toast.success(response?.data?.message);
        setProgressUploadFiles(0);
        toggleDrawerCreate("right", false);
        setDidCreate(!didCreate);
      })
      .catch((error) => {
        console.log(error);
        setProgressUploadFiles(0);
      });
  };

  //update category
  const onSubmitUpdateCategory = (values, errors) => {
    const formData = new FormData();
    formData.append("name", values?.name);
    if (values?.icon !== null) {
      formData.append("file", values?.icon);
    }
    if (values?.file !== null) {
      formData.append("icon", values?.file);
    }

    console.log("formdata to update", formData);

    if (isObjectEmpty(errors)) {
      Api.post(Urls?.STORE_CATEGORY_UPDATE + `/${category?.id}`, formData, configsPic)
        .then((response) => {
          toast.success(response?.data?.message);
          setProgressUploadFiles(0);
          toggleDrawerUpdate("right", false);
          setDidUpdate(!didUpdate);
        })
        .catch((error) => {
          console.log(error);
          setProgressUploadFiles(0);
        });
    }
  };

  //form update
  const formUpdate = (anchor) => (
    <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }} role="presentation" onClick={(event) => event.stopPropagation()} onKeyDown={(event) => event.stopPropagation()}>
      <Box padding="24px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography fontSize="18px" color="#4B465C" fontWeight="600">
            Update category
          </Typography>
        </Box>
        <Box>
          <ButtonSquare action="close" onClick={toggleDrawerUpdate("right", false)} />
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "24px" }} />
      {/* form update */}
      <Box padding="24px">
        <Box>
          <Formik
            initialValues={{
              name: category?.name,
              icon: null,
              file: null,
            }}
            validationSchema={validationSchemaUpdate}
            enableReinitialize={true}
          >
            {({ values, errors }) => (
              <Form>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <Box display="flex" gap="16px" flexDirection="column" sx={{ marginBottom: "24px" }}>
                  <Box>
                    <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                      Category name
                    </Typography>
                    <Field autoComplete="off" name="name" placeholder="Choose a name" id="name" as={TextField} fullWidth />
                    <ErrorMessage name="name" component={FormikErrorText} />
                  </Box>

                  <Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                        Category icon
                      </Typography>
                      <Field autoComplete="off" name="icon" placeholder="Choose a file" id="icon" component={FileUploadTextField} fullWidth />
                      <ErrorMessage name="icon" component={FormikErrorText} />
                    </Box>
                  </Box>

                  <Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                        Category cover image
                      </Typography>
                      <Field autoComplete="off" name="file" placeholder="Choose a file" id="file" component={FileUploadTextField} fullWidth />
                      <ErrorMessage name="file" component={FormikErrorText} />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" gap="16px">
                  <Button onClick={() => onSubmitUpdateCategory(values, errors)} sx={{ height: "38px" }} variant="primary_active">
                    Update
                  </Button>
                  <Button sx={{ height: "38px" }} variant="secondary_gray" onClick={toggleDrawerUpdate("right", false)}>
                    Discard
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );

  //form create
  const formCreate = (anchor) => (
    <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }} role="presentation" onClick={(event) => event.stopPropagation()} onKeyDown={(event) => event.stopPropagation()}>
      <Box padding="24px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography fontSize="18px" color="#4B465C" fontWeight="600">
            Create category
          </Typography>
        </Box>
        <Box>
          <ButtonSquare action="close" onClick={toggleDrawerCreate("right", false)} />
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "24px" }} />
      {/* form create */}
      <Box padding="24px">
        <Box>
          <Formik
            initialValues={{
              name: "",
              icon: null,
              file: null,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitCreateCategory}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <Box display="flex" gap="16px" flexDirection="column" sx={{ marginBottom: "24px" }}>
                  <Box>
                    <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                      Category name
                    </Typography>
                    <Field autoComplete="off" name="name" placeholder="Choose a name" id="name" as={TextField} fullWidth />
                    <ErrorMessage name="name" component={FormikErrorText} />
                  </Box>

                  <Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                        Category icon
                      </Typography>
                      <Field autoComplete="off" name="icon" placeholder="Choose a file" id="icon" component={FileUploadTextField} fullWidth />
                      <ErrorMessage name="icon" component={FormikErrorText} />
                    </Box>
                  </Box>

                  <Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                        Category cover image
                      </Typography>
                      <Field autoComplete="off" name="file" placeholder="Choose a file" id="file" component={FileUploadTextField} fullWidth />
                      <ErrorMessage name="file" component={FormikErrorText} />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" gap="16px">
                  <Button sx={{ height: "38px" }} variant="primary_active" type="submit">
                    Add
                  </Button>
                  <Button sx={{ height: "38px" }} variant="secondary_gray" onClick={toggleDrawerCreate("right", false)}>
                    Discard
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
        <Box>
          <BreadcrumComp first="Store" second="Categories" />
        </Box>
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Categories
              </Typography>
            </Box>
            <Box marginTop="8px" display="flex" flexWrap="wrap" gap="16px" flexDirection="row" justifyContent="space-between">
              <TextField value={search} onChange={handleSearchChange} type="input" placeholder="Search category" />
              <Button variant="primary_active" sx={{ height: "38px" }} onClick={toggleDrawerCreate("right", true)}>
                Add a category
              </Button>
            </Box>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {!loading && (
              <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
                <StyledTable aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {categories_columns.map((col, index) => (
                        <StyledTableCell key={index} width={col.width} align={col.align}>
                          {col.name}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categories?.map((category) => (
                      <StyledTableRow key={category?.id}>
                        <StyledTableCell align="left">
                          <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                            #{category?.id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Box>
                            <TableShowCategory url_photo={category?.url_thumbnail} category_name={category?.name} />
                          </Box>
                        </StyledTableCell>

                        {/* dummy data (no backend data available yet) */}
                        <StyledTableCell align="left">{category?.products_count}</StyledTableCell>

                        <StyledTableCell align="left">
                          <Box justifyContent="left" display="flex" gap="16px">
                            <ButtonSquare action="edit_outlined" onClick={toggleDrawerUpdate("right", true, category?.id)} />
                            <ButtonSquare
                              action="delete_red"
                              onClick={() => {
                                setSelectedRowToDelete(category.id);
                                handleOpenDeleteCategory();
                              }}
                            />
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}
            {categories?.length === 0 && !loading && <Typography textAlign="center">No categories</Typography>}

            {/* modal delete */}
            <Modal open={openDeleteCategory} onClose={handleCloseDeleteCategory}>
              <Box sx={modalStyleOverflow}>
                <Box position="absolute" top="2px" right="2px">
                  <ButtonSquare action="close" onClick={handleCloseDeleteCategory} />
                </Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                    Delete category
                  </Typography>
                  <Typography fontSize="16px" fontWeight="400" color="#4B465C">
                    Are you sure you want to delete category {`#${selectedRowToDelete}`} ?
                  </Typography>
                  <Box display="flex" flexDirection="row-reverse" gap="10px">
                    <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseDeleteCategory}>
                      Cancel
                    </Button>
                    <Button variant="primary_active" sx={{ height: "38px" }} onClick={() => handleDeleteCategory(selectedRowToDelete)}>
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>

            <Drawer anchor={"right"} open={stateUpdate["right"]} onClose={toggleDrawerUpdate("right", false)}>
              {formUpdate("right")}
            </Drawer>

            <Drawer anchor={"right"} open={stateCreate["right"]} onClose={toggleDrawerCreate("right", false)}>
              {formCreate("right")}
            </Drawer>

            <Box display="flex" gap="10px" flexDirection="row-reverse">
              <Box>
                {data?.links?.next && (
                  <>
                    <Button
                      onClick={() => {
                        handlePagination(data?.links?.next);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Next
                    </Button>
                  </>
                )}
              </Box>

              <Box display="flex" gap="10px">
                {data?.meta?.links?.map((link, index) => (
                  <ButtonSquare
                    key={index}
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                ))}
              </Box>

              <Box>
                {data?.links?.prev && (
                  <>
                    <Button
                      onClick={() => {
                        handlePagination(data?.links?.prev);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Previous
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </ContentBox>
      </Box>
    </>
  );
}

export default Categories;
