import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Avatar, Box, Button, CircularProgress, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import CartIcon from "../../imgs/cart-red.svg";
import chartIcon from "../../imgs/chart.svg";
import openIcon from "../../imgs/open.svg";
import coachIcon from "../../imgs/coach-icon.svg";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { AddCircleOutlineRounded, OpenInBrowser } from "@mui/icons-material";
import ContentBox from "../../components/ui/ContentBox";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import moment from "moment";
import { toast } from "react-toastify";
import TableShowUser from "../../components/ui/TableShowUser";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const BarChartComponent = ({ height }) => {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [openMenu, setOpenMenu] = useState(false);
  const [usersData, setUsersData] = useState({});
  const [selectedCoach, setSelectedCoach] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedType, setSelectedType] = useState("booking");
  const [data, setData] = useState({});
  const [totalIncome, setTotalIncome] = useState(0);
  useEffect(() => {
    if (selectedCoach && selectedType === "coaches") {
      if (!loading) {
        setLoading(true);
      }
      Api.get(Urls.GET_COACH_REPORTS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          coach_id: selectedCoach?.id,
        },
      })
        .then((res) => {
          console.log(res);
          reorganizeData(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
    if (selectedType === "booking") {
      if (!loading) {
        setLoading(true);
      }
      Api.get(Urls.GET_BOOKING_REPORTS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          console.log(res);
          reorganizeDataBooking(res?.data?.bookingReports);
          setTotalIncome(res?.data?.totalIncomeBooking);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  }, [selectedCoach, selectedType]);
  //get users
  useEffect(() => {
    Api.get(Urls?.GET_COACHES, {
      headers: { "content-type": "application/json", Authorization: `Bearer ${access_token}` },
      params: {
        perPage: 2,
      },
    })
      .then((res) => {
        setUsers(res?.data?.data);
        setSelectedCoach(res?.data?.data[0]);
        setUsersData(res?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);

  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setUsersData(res?.data);
        setUsers(users.concat(res?.data?.data));
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setUsers([]);
      });
  };
  const reorganizeData = (data) => {
    let months = [];
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let month = moment(element?.date).format("MMMM");
      months.push(month);
      dataArray.push(element?.total_cost);
    }
    setData({
      labels: months,
      datasets: [
        {
          label: "Coach reports ",
          data: dataArray,
          backgroundColor: ["#EF0000"],
          borderColor: "transparent",
          barThickness: 28,
          borderWidth: 0,
          borderRadius: 8,
        },
      ],
    });
  };
  const reorganizeDataBooking = (data) => {
    let weeks = [];
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let week = element?.year_week?.toString();
      week = week?.slice(4);
      weeks.push(week);
      dataArray.push(element?.total_income);
    }
    console.log(weeks, "weeks");
    setData({
      labels: weeks,
      datasets: [
        {
          label: "Booking reports",
          data: dataArray,
          backgroundColor: ["#EF0000"],
          borderColor: "transparent",
          barThickness: 28,
          borderWidth: 0,
          borderRadius: 8,
        },
      ],
    });
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      labels: {
        render: "value", // Render the actual value on top of each bar
      },
    },
    elements: {
      bar: {
        borderWidth: 0, // Set the border width of the bars to 0 to remove bar borders
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Set display to false to remove x-axis gridlines
        },
        ticks: {
          color: "rgba(75, 70, 92, 1)", // Set color to transparent to hide the y-axis tick labels
          fontSize: "13px",
          lineHeight: "20px",
          fontWeight: "400",
        },
      },
      y: {
        grid: {
          display: false, // Set display to false to remove y-axis gridlines
        },
        ticks: {
          color: "rgba(75, 70, 92, 1)", // Set color to transparent to hide the y-axis tick labels
          fontSize: "13px",
          lineHeight: "20px",
          fontWeight: "400",
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };
  console.log(data, "data");
  return (
    <ContentBox>
      <Box sx={{ backgroundColor: "white" }} display="flex" flexDirection="column" gap="48px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
              Earning Reports{" "}
            </Typography>
            <Typography fontSize="13px" fontWeight="400" lineHeight="20px" color="#4B465C">
              Earnings Overview by terms{" "}
            </Typography>
          </Box>
        </Box>
        <Box width="100%" display="flex" gap="42px" sx={{ backgroundColor: "inherit", flexDirection: { xs: "column", sm: "column", md: "column", lg: "column" } }} justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="column" gap="26px">
            <Box display="flex" alignItems="center" gap="16px">
              <Box onClick={() => setSelectedType("booking")} width="100px" padding="15px 12px" borderRadius="10px" gap="8px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ cursor: "pointer" }} border={`1px solid${selectedType === "booking" ? " rgba(239, 0, 0, 1)" : " lightgray"}`}>
                <Avatar sx={{ borderRadius: "8px", padding: "6px", backgroundColor: "rgba(239, 0, 0, 0.1)" }}>
                  <img src={CartIcon} alt="cart" />
                </Avatar>
                <Typography fontSize="15px" fontWeight="500" lineHeight="22px" color="#4B465C">
                  Booking
                </Typography>
              </Box>
              <Box onClick={() => setSelectedType("coaches")} width="100px" padding="15px 12px" borderRadius="10px" gap="8px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ cursor: "pointer" }} border={`1px solid${selectedType === "coaches" ? " rgba(239, 0, 0, 1)" : " lightgray"}`}>
                <Avatar sx={{ borderRadius: "8px", padding: "6px", backgroundColor: "rgba(239, 0, 0, 0.1)" }}>
                  <img src={coachIcon} alt="cart" />
                </Avatar>
                <Typography fontSize="15px" fontWeight="500" lineHeight="22px" color="#4B465C">
                  Coaches
                </Typography>
              </Box>
            </Box>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {data?.labels && !loading && (
              <Box sx={{ minWidth: { xs: "300px", sm: "400px", md: "500px", lg: "500px" }, maxWidth: { xs: "300px", sm: "400px", md: "500px", lg: "500px" } }}>
                <Bar data={data} options={options} />
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" gap="36px">
            {selectedType === "coaches" && users?.length === 0 && <Typography sx={{ textAlign: "center" }}>No coachs</Typography>}
            {selectedType === "coaches" && users?.length > 0 && (
              <Box position="relative" alignItems="center">
                <Button onClick={() => (openMenu ? setOpenMenu(false) : setOpenMenu(true))} variant="outlined" sx={{ borderRadius: "8px", padding: "2px 20px", color: "#EF0000" }}>
                  {selectedCoach?.first_name + " " + selectedCoach?.last_name}
                  <IconButton>
                    <img src={openIcon} alt="open" />
                  </IconButton>
                </Button>
                {openMenu && (
                  <Box backgroundColor="white" position="absolute" sx={{ zIndex: 99 }} top="40px" display="flex" flexDirection="column" minWidth="300px" boxShadow="0px 20px 20px 0px rgba(165, 163, 174, 0.20)">
                    {users?.map((user) => (
                      <Box
                        onClick={() => {
                          setSelectedCoach(user);
                          setOpenMenu(false);
                        }}
                        backgroundColor="white"
                        sx={{ filter: `${selectedCoach?.id === user?.id ? "brightness(0.95)" : "none"}`, cursor: "pointer", ":hover": { filter: "brightness(0.95)" } }}
                        padding="10px"
                      >
                        <TableShowUser url_photo={user?.url_avatar} full_name={user?.first_name + " " + user?.last_name} />
                      </Box>
                    ))}
                    {usersData?.links?.next && (
                      <Box onClick={() => handlePagination(usersData?.links?.next)} display="flex" justifyContent="center" backgroundColor="white" sx={{ cursor: "pointer", ":hover": { filter: "brightness(0.95)" } }} padding="10px">
                        <AddCircleOutlineRounded />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}

            {selectedType === "booking" && (
              <Box sx={{ zIndex: openMenu ? -1 : 0 }}>
                <Typography fontSize="26px" fontWeight="500" lineHeight="36px" color="#4B465C">
                  {" "}
                  {parseFloat(totalIncome).toLocaleString("fr-FR")} BHD
                </Typography>
                <Box>
                  <Typography display="inline" fontSize="15px" fontWeight="500" lineHeight="22px" color="#4B465C">
                    Total income booking
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ContentBox>
  );
};

export default BarChartComponent;
