import { Autocomplete, Box, Button, TextField, Typography, Modal, Chip, IconButton, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../components/ui/ContentBox";
import { terms_columns } from "../../components/data/termsColumns";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import { terms_rows } from "../../components/data/termsRows";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import BtaDatePicker from "../../components/ui/BtaDatePicker";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import * as Yup from "yup";
import moment from "moment";

function Terms() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [terms, setTerms] = useState([]);
  const [openCreateTerm, setOpenCreateTerm] = useState(false);
  const [openDeleteTerm, setOpenDeleteTerm] = useState(false);
  const [openUpdateTerm, setOpenUpdateTerm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [didCreate, setDidCreate] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [didDelete, setDidDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState({ open: false, data: null });
  const [didUpdate, setDidUpdate] = useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState();
  const [selectedRowToUpdate, setSelectedRowToUpdate] = useState();
  const [data, setData] = useState([]);
  const [term, setTerm] = useState();
  const perPage = "?perPage=10";
  useEffect(() => {
    document.title = "BTA admin - Terms";
  }, []);
  //get list terms
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_TERMS_LIST + perPage, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("terms list:", response.data.data);
        setTerms(response?.data?.data);
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  }, [didCreate, didDelete, didUpdate]);

  //get term by id
  const getTermById = (id) => {
    Api.get(Urls.GET_TERM + `/${id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("single term:", response.data.data);
        setTerm(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setTerms(res.data?.data);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setTerms([]);
      });
  };

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCloseCreateTerm = () => {
    setOpenCreateTerm(false);
  };

  const handleCloseDeleteTerm = () => {
    setOpenDeleteTerm(false);
  };

  const handleCloseUpdateTerm = () => {
    setOpenUpdateTerm(false);
  };
  const handleClosePublish = () => {
    setOpenPublish(false);
  };

  const initialValuesCreateTerm = {
    name: "",
    start_date: "",
    end_date: "",
  };
  const [initialValuesDup, setInitialValuesDup] = useState({
    name: "",
    start_date: "",
    end_date: "",
  });

  const initialValuesUpdateTerm = {
    name: term?.name || "",
    start_date: term?.start_date || "",
    end_date: term?.end_date || "",
  };

  const validationSchemaCreateTerm = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    start_date: Yup.date().typeError("Date must be valid").required("This field is required"),
    end_date: Yup.date().typeError("Date must be valid").required("This field is required"),
  });
  const validationSchemaUpdateTermPublished = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });
  const validationSchemaUpdateTermNotPublished = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    start_date: Yup.date().typeError("Date must be valid").required("This field is required"),
    end_date: Yup.date().typeError("Date must be valid").required("This field is required"),
  });
  const onSubmitCreateTerm = (values) => {
    Api.post(
      Urls?.CREATE_TERM,
      {
        name: values.name,
        start_date: values.start_date,
        end_date: values.end_date,
      },
      { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((response) => {
        console.log(response);
        setOpenCreateTerm(false);
        toast.success(response.data.message);
        setDidCreate(!didCreate);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };
  const onSubmitDuplicateTerm = (values) => {
    Api.post(
      Urls?.DUPLICATE_TERM + openDuplicate?.data?.id + "/duplicate",
      {
        name: values.name,
        start_date: values.start_date,
        end_date: values.end_date,
      },
      { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((response) => {
        setOpenDuplicate({ open: false, data: null });
        toast.success(response.data.message);
        setDidCreate(!didCreate);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const onSubmitUpdateTerm = (values, id) => {
    Api.patch(
      Urls?.UPDATE_TERM + `/${id}`,
      {
        name: values.name,
        ...(values?.start_date && !term?.published_at && { start_date: values.start_date }),
        ...(values?.end_date && !term?.published_at && { end_date: values.end_date }),
      },
      { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((response) => {
        console.log(response);
        setOpenUpdateTerm(false);
        toast.success(response.data.message);
        setDidUpdate(!didUpdate);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const handleDeleteTerm = (id) => {
    Api.delete(Urls?.DELETE_TERM + `/${id}`, { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } })
      .then((response) => {
        console.log(response);
        setOpenDeleteTerm(false);
        toast.success(response.data.message);
        setDidDelete(!didDelete);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };
  const handlePublishTerm = (id) => {
    Api.post(
      Urls.PUBLISH_TERM + id + "/publish",
      {},
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenPublish(false);
        setDidUpdate(!didUpdate);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleClickDup = (term) => {
    setOpenDuplicate({ open: true, data: term });
    setInitialValuesDup({
      name: term?.name,
      start_date: term?.start_date,
      end_date: term?.end_date,
    });
  };
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ToastContainer />
      <Box>
        <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
          Terms
        </Typography>
      </Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
              List of terms
            </Typography>
          </Box>
          <Box flexWrap="wrap" marginTop="8px" display="flex" gap="16px" flexDirection="row" justifyContent="space-between">
            <Box>
              <Button sx={{ height: "38px" }} variant="primary_active" onClick={() => setOpenCreateTerm(true)}>
                Create term
              </Button>
            </Box>
            <TextField value={search} onChange={handleSearchChange} type="input" placeholder="Search term" />
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {terms_columns.map((col, index) => (
                      <StyledTableCell key={index} width={col.width} align={col.align}>
                        {col.name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {terms?.map((term) => (
                    <StyledTableRow key={term?.id}>
                      <StyledTableCell align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{term?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">{term.name}</StyledTableCell>
                      <StyledTableCell align="left">{moment(term.start_date).format("dddd, MMMM DD, yyyy")}</StyledTableCell>
                      <StyledTableCell align="left">{moment(term.end_date).format("dddd, MMMM DD, yyyy")}</StyledTableCell>
                      <StyledTableCell align="left">{term.weeks_count}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip label={term?.published_at ? "PUBLISHED" : "UNPUBLISHED"} sx={{ backgroundColor: `${term?.published_at ? "rgba(40, 199, 111, 0.16)" : "rgba(234, 84, 85, 0.16)"}`, color: `${term?.published_at ? "rgba(40, 199, 111, 1)" : "rgba(234, 84, 85, 1)"}`, borderRadius: "8px" }} />
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <Box justifyContent="left" display="flex" alignItems="center" gap="16px">
                          <ButtonSquare onClick={() => navigate(`/admin/terms/${term.id}`)} action="show_outlined" />
                          <ButtonSquare
                            action="edit_outlined"
                            onClick={() => {
                              setOpenUpdateTerm(true);
                              getTermById(term.id);
                              setSelectedRowToUpdate(term.id);
                            }}
                          />
                          <ButtonSquare
                            action="delete_red"
                            onClick={() => {
                              setOpenDeleteTerm(true);
                              setSelectedRowToDelete(term.id);
                            }}
                          />
                          {!term?.published_at && (
                            <Button
                              onClick={() => {
                                setOpenPublish(true);
                                setSelectedRowToDelete(term.id);
                              }}
                              variant="secondary_gray"
                            >
                              Publish
                            </Button>
                          )}
                          <IconButton onClick={() => handleClickDup(term)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          )}
          {terms?.length === 0 && !loading && <Typography textAlign="center">No terms</Typography>}

          <Box display="flex" gap="10px" flexDirection="row-reverse">
            <Box>
              {data?.links?.next && (
                <>
                  <Button
                    onClick={() => {
                      handlePagination(data?.links?.next);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    Next
                  </Button>
                </>
              )}
            </Box>

            <Box display="flex" gap="10px">
              {data?.meta?.links?.map((link, index) => (
                <ButtonSquare
                  key={index}
                  onClick={() => {
                    handlePagination(link.url);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  action={link?.active ? "number-active" : "number"}
                  number={link.label}
                />
              ))}
            </Box>

            <Box>
              {data?.links?.prev && (
                <>
                  <Button
                    onClick={() => {
                      handlePagination(data?.links?.prev);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    Previous
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </ContentBox>

      {/* modal create term */}
      <Modal open={openCreateTerm} onClose={handleCloseCreateTerm}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseCreateTerm} />
          </Box>
          <Box>
            <Formik onSubmit={onSubmitCreateTerm} validationSchema={validationSchemaCreateTerm} initialValues={initialValuesCreateTerm} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Create term
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Name
                      </Typography>
                      <Field style={{ width: "100%" }} name="name" type="input" id="name" as={TextField} autoComplete="off" placeholder="Summer season 2024" />
                      <ErrorMessage component={FormikErrorText} name="name" />
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap="26px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Start date
                        </Typography>
                        <Field width="100%" name="start_date" type="input" id="start_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="start_date" />
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          End date
                        </Typography>
                        <Field width="100%" name="end_date" type="input" id="end_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="end_date" />
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="row-reverse" gap="10px">
                      <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseCreateTerm}>
                        Cancel
                      </Button>
                      <Button variant="primary_active" sx={{ height: "38px" }} type="submit">
                        Create
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      {/* modal create term */}
      <Modal open={openDuplicate?.open} onClose={() => setOpenDuplicate({ open: false, data: null })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenDuplicate({ open: false, data: null })} />
          </Box>
          <Box>
            <Formik onSubmit={onSubmitDuplicateTerm} validationSchema={validationSchemaCreateTerm} initialValues={initialValuesDup} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Duplicate term
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Name
                      </Typography>
                      <Field style={{ width: "100%" }} name="name" type="input" id="name" as={TextField} autoComplete="off" placeholder="Summer season 2024" />
                      <ErrorMessage component={FormikErrorText} name="name" />
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap="26px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Start date
                        </Typography>
                        <Field width="100%" name="start_date" type="input" id="start_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="start_date" />
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          End date
                        </Typography>
                        <Field width="100%" name="end_date" type="input" id="end_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="end_date" />
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="row-reverse" gap="10px">
                      <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={() => setOpenDuplicate({ open: false, data: null })}>
                        Cancel
                      </Button>
                      <Button variant="primary_active" sx={{ height: "38px" }} type="submit">
                        Duplicate
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      {/* modal delete */}
      <Modal open={openDeleteTerm} onClose={handleCloseDeleteTerm}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseDeleteTerm} />
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Delete term
            </Typography>
            <Typography fontSize="16px" fontWeight="400" color="#4B465C">
              Are you sure you want to delete term {`#${selectedRowToDelete}`} ?
            </Typography>
            <Box display="flex" flexDirection="row-reverse" gap="10px">
              <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseDeleteTerm}>
                Cancel
              </Button>
              <Button variant="primary_active" sx={{ height: "38px" }} onClick={() => handleDeleteTerm(selectedRowToDelete)}>
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* modal publish */}
      <Modal open={openPublish} onClose={handleClosePublish}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleClosePublish} />
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Publish term
            </Typography>
            <Typography fontSize="16px" fontWeight="400" color="#4B465C">
              Are you sure you want to publish term {`#${selectedRowToDelete}`} ?
            </Typography>
            <Box display="flex" flexDirection="row-reverse" gap="10px">
              <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleClosePublish}>
                Cancel
              </Button>
              <Button variant="primary_active" sx={{ height: "38px" }} onClick={() => handlePublishTerm(selectedRowToDelete)}>
                Publish
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Modal update */}
      <Modal open={openUpdateTerm} onClose={handleCloseUpdateTerm}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseUpdateTerm} />
          </Box>
          <Box>
            <Formik
              onSubmit={(values) => {
                onSubmitUpdateTerm(values, selectedRowToUpdate);
              }}
              validationSchema={term?.published_at ? validationSchemaUpdateTermPublished : validationSchemaUpdateTermNotPublished}
              initialValues={initialValuesUpdateTerm}
              enableReinitialize={true}
            >
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Update term
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Name
                      </Typography>
                      <Field style={{ width: "100%" }} name="name" type="input" id="name" as={TextField} autoComplete="off" placeholder="Summer season 2024" />
                      <ErrorMessage component={FormikErrorText} name="name" />
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap="26px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Start date
                        </Typography>
                        <Field disabled={term?.published_at ? true : false} width="100%" name="start_date" type="input" id="start_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="start_date" />
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          End date
                        </Typography>
                        <Field disabled={term?.published_at ? true : false} width="100%" name="end_date" type="input" id="end_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="end_date" />
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="row-reverse" gap="10px">
                      <Button variant="secondary_gray" sx={{ height: "38px" }} onClick={handleCloseUpdateTerm}>
                        Cancel
                      </Button>
                      <Button variant="primary_active" sx={{ height: "38px" }} type="submit">
                        Update
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Terms;
