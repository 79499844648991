import { Autocomplete, Box, Button, TextField, Typography, Modal, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../components/ui/ContentBox";
import { terms_columns } from "../../components/data/termsColumns";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import { terms_rows } from "../../components/data/termsRows";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import BtaDatePicker from "../../components/ui/BtaDatePicker";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import { courts_columns } from "../../components/data/courtsColumns";
import * as Yup from "yup";
import { level_rows } from "../../components/data/levelRows";
import { level_columns } from "../../components/data/levelColumns";

function Levels() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const [levels, setLevels] = useState([]);
  const [openAddLevel, setOpenAddLevel] = useState(false);
  const [change, setChange] = useState(false);
  const [openEditLevel, setOpenEditLevel] = useState({
    open: false,
    data: null,
  });
  const [openDeleteLevel, setOpenDeleteLevel] = useState({
    open: false,
    data: null,
  });
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));
  const [initialValues, setinitialValues] = useState({
    level_name: "",
  });
  const validationSchema = Yup.object({
    level_name: Yup.string().required("this field is mandatory"),
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));
  useEffect(() => {
    document.title = "BTA admin - Levels";
  }, []);
  const handleCloseAdd = () => {
    setOpenAddLevel(false);
  };
  const handleCloseUpdate = () => {
    setOpenEditLevel({
      open: false,
      data: null,
    });
    setinitialValues({
      level_name: "",
    });
  };
  const handleCloseDeleteLevel = () => {
    setOpenDeleteLevel({
      open: false,
      data: null,
    });
  };
  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  useEffect(() => {
    Api.get(Urls?.GET_LEVELS, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { search: search }),
      },
    })
      .then((res) => {
        setLevels(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, [change, search]);
  const submitCreateLevel = (values) => {
    Api.post(
      Urls?.CREATE_LEVELS,
      {
        name: values.level_name,
      },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success("Created");
        setChange(!change);
        handleCloseAdd();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const submitUpdateLevel = (values) => {
    Api.patch(
      Urls?.UPDATE_LEVELS + openEditLevel?.data?.id,
      {
        name: values.level_name,
      },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success("Updated");
        setChange(!change);
        handleCloseUpdate();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const submitDeleteLevel = (values) => {
    Api.delete(Urls?.DELETE_LEVELS + openDeleteLevel?.data?.id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast?.success("Deleted");
        setChange(!change);
        handleCloseDeleteLevel();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ToastContainer />
      <Box>
        <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
          Levels
        </Typography>
      </Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
              List of levels
            </Typography>
          </Box>
          <Box marginTop="8px" flexWrap="wrap" display="flex" gap="16px" flexDirection="row" justifyContent="space-between">
            <Box>
              <Button sx={{ height: "38px" }} variant="primary_active" onClick={() => setOpenAddLevel(true)}>
                Create level
              </Button>
            </Box>
            <TextField value={search} onChange={(e) => setSearch(e.target?.value)} type="input" placeholder="Search level" />
          </Box>
          {!loading && (
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {level_columns.map((col, index) => (
                      <StyledTableCell key={index} width={col.width} align={col.align}>
                        {col.name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {levels?.map((level) => (
                    <StyledTableRow key={level?.id}>
                      <StyledTableCell align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{level?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">{level.name}</StyledTableCell>
                      <StyledTableCell align="left">
                        <Box justifyContent="left" display="flex" gap="16px">
                          <ButtonSquare
                            action="edit_outlined"
                            onClick={() => {
                              setOpenEditLevel({
                                open: true,
                                data: level,
                              });
                              setinitialValues({
                                level_name: level?.name,
                              });
                            }}
                          />

                          {/* disable delete level ids < 5 */}
                          {level?.id > 4 && (
                            <ButtonSquare
                              action="delete_red"
                              onClick={() => {
                                setOpenDeleteLevel({
                                  open: true,
                                  data: level,
                                });
                              }}
                            />
                          )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          )}
          {levels?.length === 0 && !loading && <Typography textAlign="center">No levels</Typography>}

          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </ContentBox>
      <Modal open={openAddLevel} onClose={handleCloseAdd}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseAdd} />
          </Box>
          <Box>
            <Formik onSubmit={(values) => submitCreateLevel(values)} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Add level
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Level name
                        </Typography>
                        <Field style={{ width: "100%" }} name="level_name" type="input" id="level_name" as={TextField} autoComplete="off" placeholder="Level..." />
                        <ErrorMessage component={FormikErrorText} name="level_name" />
                      </Box>
                    </Box>
                    <Box marginTop="10px" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        Add
                      </Button>
                      <Button onClick={handleCloseAdd} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openEditLevel?.open} onClose={handleCloseUpdate}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseUpdate} />
          </Box>
          <Box>
            <Formik onSubmit={(values) => submitUpdateLevel(values)} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Add level
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Level name
                        </Typography>
                        <Field style={{ width: "100%" }} name="level_name" type="input" id="level_name" as={TextField} autoComplete="off" placeholder="Level..." />
                        <ErrorMessage component={FormikErrorText} name="level_name" />
                      </Box>
                    </Box>
                    <Box marginTop="10px" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        update
                      </Button>
                      <Button onClick={handleCloseUpdate} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openDeleteLevel?.open} onClose={handleCloseDeleteLevel}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={handleCloseDeleteLevel} />
          </Box>
          <Box marginBottom="26px">
            <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Delete level
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
              Are you sure you want to delete level {openDeleteLevel?.data?.name}
            </Typography>
          </Box>
          <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
            <Button onClick={submitDeleteLevel} variant="primary_active">
              Delete
            </Button>
            <Button onClick={handleCloseDeleteLevel} variant="primary_inactive">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Levels;
